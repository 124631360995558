.cardContainer {
    display: flex;
    flex-direction: row;
}

.cardImage {
    height: 150px;
    width: 150px;
    padding: 10px;
}

.cardText {
    padding: 10px;
}

.cardButton {
    margin: 10px;
}

@media only screen and (min-width: 500px) {
    /* For desktop: */
    .cardContainer {
        display: block;
    }
    
    .cardImage {
        min-height: 50%;
        padding: 20px;
    }
    
    .cardText {
        padding: 10px;
    }
    
    .cardButton {
        margin: 10px;
    }
  }
